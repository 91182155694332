<template>
  <div class="mt-14 pl-8 pr-8">
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-margin">
      <v-row class="mb-5">
        <div class="text-left">
          <h1 class="ocupation__title">Carnet</h1>
        </div>
      </v-row>
      <v-row>
        <v-col cols="5">
          <template>
            <v-card outlined class="px-8 mt-8 card" elevation="0">
              <v-row class="mt-5 mb-5">
                <v-col>
                  <div class="carnet">
                    <img src="../../assets/img/carnet.png" />
                    <div class="profile-picture">
                      <img :src="this.currentUser.img" alt="Profile Picture" />
                    </div>
                    <div
                      class="mt-15 mb-15 d-flex flex-column align-items-start text-left"
                    >
                      <p class="info-text">Nombre: {{ userName }}</p>
                      <p class="info-text">Cargo: {{ shortProfile }}</p>
                      <p class="info-text">
                        Cedula: {{ currentUser.document_number }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-col>
        <v-col cols="6">
          <template>
            <div class="mt-20">
              <v-row justify="center">
                <v-col cols="12">
                  <v-card class="custom-card">
                    <v-card-title class="text-center"
                      >Información Importante</v-card-title
                    >
                    <v-card-text>
                      El código de seguridad para el día:
                      <strong>{{ this.dataCodigo.fecha }}</strong> ubicado en la
                      posición:
                      <strong>{{ this.dataCodigo.posicion }}</strong> y el
                      código es: <strong>{{ this.dataCodigo.codigo }}</strong>
                    </v-card-text>
                    <v-card-title class="text-center"
                      >Ejemplo de Codigo</v-card-title
                    >
                    <v-card-text >
                      <strong class="text-apps-blue-sm">
                        {{
                          this.generarCadenaAleatoria(
                            this.dataCodigo.codigo,
                            this.dataCodigo.posicion
                          )
                        }}</strong
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
    <div class="mb-sm-margin">
      <v-row justify="center" class="ml-0 mr-0 px-3 my-4 pb-8">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            Detalles del Carnet
          </p>
        </v-col>
        <v-col cols="12" class="py-0 px-0">
          <template>
            <div class="mt-20">
              <v-row justify="center">
                <v-col cols="12">
                  <v-card class="custom-card">
                    <v-card-title class="text-center"
                      >Codigo seguridad</v-card-title
                    >
                    <v-card-text>
                      El código de seguridad para el día:
                      <strong>{{ this.dataCodigo.fecha }}</strong> ubicado en la
                      posición:
                      <strong>{{ this.dataCodigo.posicion }}</strong> y el
                      código es:
                      <strong class="text-apps-blue-sm">{{
                        this.dataCodigo.codigo
                      }}</strong>
                    </v-card-text>

                    <v-card-title class="text-center"
                      >Ejemplo de Codigo</v-card-title
                    >
                    <v-card-text >
                      <strong class="text-apps-blue-sm">
                        {{
                          this.generarCadenaAleatoria(
                            this.dataCodigo.codigo,
                            this.dataCodigo.posicion
                          )
                        }}</strong
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
      </v-row>
    </div>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }} 
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<!-- Link de flujo:// -->
<script>
import Api from "@/utils/api";
export default {
  data: () => ({
    token: "",
    currentUser: "",
    loadingAll: false,
    dataCodigo: null,
    shortProfile: "",
    userName: "",
  }),

  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getCurrent() {
      if (this.$store.getters.getUser) {
        this.currentUser = this.$store.getters.getUser;
        let name = this.currentUser.name.split(" ")[0];
        let lastname = this.currentUser.lastname.split(" ")[0];
        let fullName = name + " " + lastname;
        this.userName = this.toTitleCase(fullName);
        let profile = this.currentUser.profile.name.split("|")[0];
        this.shortProfile = this.toTitleCase(profile);
      }
    },
    getIDSecurity() {
      this.loadingAll = true;
      Api.carnet()
        .getIDSecurity(this.token)
        .then((res) => {
          this.dataCodigo = res.data.data;
        })
        .catch((error) => {
          this.message.dialog = true;
          this.message.sms = "Hemos tenido problemas con tu solicitud";
          this.message.title = "¡Oh no!";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    generarCadenaAleatoria(cadena, posicion) {
      let resultado = "";
      for (let i = 0; i < 3; i++) {
        if (i === posicion - 1) {
          resultado += cadena;
        } else {
          let caracteresAleatorios = "";
          if (i === 2) {
            for (let j = 0; j < 2; j++) {
              caracteresAleatorios += this.generarCaracterAleatorio();
            }
          } else {
            for (let j = 0; j < 3; j++) {
              caracteresAleatorios += this.generarCaracterAleatorio();
            }
          }
          resultado += caracteresAleatorios;
        }
      }

      return resultado;
    },
    generarCaracterAleatorio() {
      const caracteres =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      return caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    },
  },
  created() {
    this.getToken();
    this.getCurrent();
    this.getIDSecurity();
  },

  watch: {
    "$store.state.user": {
      handler(newValue, oldValue) {
        this.getCurrent();
        this.getIDSecurity();
      },
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.mb-xl-margin {
  display: block;
}

.mb-sm-margin {
  display: none;
}

.card {
  width: 500px;
}
.carnet {
  background-color: #355ae6;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  width: 150px; /* ajusta el tamaño según tus necesidades */
  height: 150px; /* ajusta el tamaño según tus necesidades */
  border-radius: 50%; /* para hacer la imagen redonda */
  overflow: hidden; /* oculta cualquier parte de la imagen que se salga del radio del borde */
  border: 5px solid white; /* borde blanco de 2px */
  margin-top: 10px; /* ajusta el margen superior según tus necesidades */
}

.carnet img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* para que la imagen cubra completamente el contenedor */
}

.info-text {
  font-weight: bold;
  color: white;
}
.custom-card {
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.custom-card .v-card-title {
  color: #007bff;
  font-size: 24px;
  margin-bottom: 20px;
}
.custom-card .v-card-text {
  font-size: 18px;
  line-height: 1.6;
}
.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.requests-card-margin {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .title-margin-action {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin {
    display: none;
  }
  .mb-sm-margin {
    display: block;
    text-align: initial;
  }
}
</style>
